<template>
    <AppOnePageDropdown 
        v-model="value"
        :idValue="idValue"
        optionLabel="codigo"
        :filterFields="['codigo', 'descricao']"
        placeholder="Selecione a cnae"
        :service="$service"
        :itemSize="itemSize"
        :renderValue="(slotProps) => slotProps.value?.codigo + ' - ' + slotProps.value?.descricao"
        :renderOption="(slotProps) => slotProps.option?.codigo + ' - ' + slotProps.option?.descricao"
        >
        
    </AppOnePageDropdown>
</template>
<script>
import BaseService from '@/services/BaseService'

export default {
    props: {
        modelValue: {
            type: Object
        },
        itemSize: {
            type: Number,
            default: 20
        },
        idValue: {
            type: Number
        }
    },    
    data() {
        return {
            $service: null
        }
    },
    emits: ['update:modelValue'],
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    async created() {
        this.$service = new BaseService('/cnaes');
    }
};
</script>
