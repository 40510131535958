<template>
    <AppConfirmDialog
        :loading="loading"
        :visible="dialogHerdarProdutosServicos"
        title="Há produtos já vinculados na unidade e esses serão subscritos. Deseja continuar?"
        @onClose="dialogHerdarProdutosServicos = false"
        @onConfirm="validarProdutosEServicosHerdados(true)"
    />
    <AppPanel :location="[title]" subtitle="Preencha os campos abaixo" :hasBackButton="true" backPath="/branch">
        <template #content>
            <AppFormCadastro
                :service="service"
                :form="form"
                backPath="/branch"
                @onBeforeSave="onBeforeSave"
                @onAfterSave="onAfterSave"
                @onLoadDataEdit="onLoadDataEdit"
                @onValidate="onValidate"
            >
                <template #content>
                    <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                        <AppInfoManual nomeTelaDoManual="unidades-form" />
                        <h3 class="ml-1">{{ title }}</h3>
                    </div>
                    <div class="">
                        <ConfirmPopup></ConfirmPopup>
                        <div class="grid">
                            <div class="field col-12 md:col-6">
                                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                    <div class="field flex-1">
                                        <span :style="{ color: 'red' }">*</span>
                                        <label for="customer_id">Cliente</label>
                                        <DropdownClienteInscricao
                                            id="customer_id"
                                            v-model="form.customer"
                                            :idValue="form.customerId"
                                            :class="{ 'p-invalid': submitted && !form.customerId }"
                                        />
                                        <small class="p-error" v-if="submitted && !form.customerId">Cliente é obrigatório.</small>
                                    </div>
                                    <div class="field md:ml-2 md:mt-5">
                                        <Button
                                            :loading="loading"
                                            :disabled="!form.customerId"
                                            v-if="!$route.params.id"
                                            class="p-button p-button-outlined"
                                            type="button"
                                            label="Copiar cadastro do Cliente"
                                            icon="pi pi-copy"
                                            @click="confirmarCopia($event)"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="field col-12 md:col-6">
                                <span :style="{ color: 'red' }">*</span>
                                <label for="trade_name">Razão social</label>
                                <InputText
                                    id="trade_name"
                                    v-model.trim="form.tradeName"
                                    required="true"
                                    autocomplete="off"
                                    :class="{ 'p-invalid': submitted && !form.tradeName }"
                                />
                                <small class="p-error" v-if="submitted && !form.tradeName">Razão social é obrigatório.</small>
                            </div>
                        </div>

                        <div class="grid">
                            <div class="field col-12 md:col-3">
                                <label for="tipo_inscricao">Tipo Inscrição</label>
                                <Dropdown
                                    showClear
                                    id="tipo_inscricao"
                                    v-model="form.tipoInscricao"
                                    :options="tipoInscricao"
                                    optionValue="value"
                                    @change="onChangeTipoInscricao($event)"
                                    optionLabel="label"
                                    placeholder="Selecione..."
                                    :class="{ 'p-invalid': submitted && !form.tipoInscricao }"
                                />
                                <small class="p-error" v-if="submitted && !form.tipoInscricao">Tipo inscrição é obrigatório.</small>
                            </div>
                            <div class="field col-12 md:col-3">
                                <span :style="{ color: 'red' }">*</span>
                                <label for="inscricao">Inscrição</label>
                                <InputMask
                                    id="inscricao"
                                    v-if="form.tipoInscricao === 'CNPJ'"
                                    mask="99.999.999/9999-99"
                                    unmask
                                    v-model.trim="form.tradeCode"
                                    autocomplete="off"
                                    :class="{ 'p-invalid': submitted && !form.tradeCode }"
                                />
                                <InputMask
                                    id="inscricao"
                                    v-if="form.tipoInscricao === 'CPF'"
                                    mask="999.999.999-99"
                                    unmask
                                    v-model.trim="form.tradeCode"
                                    autocomplete="off"
                                    :class="{ 'p-invalid': submitted && !form.tradeCode }"
                                />
                                <small class="p-error" v-if="submitted && !form.tradeCode">Inscrição é obrigatório.</small>
                            </div>
                            <div class="field col-12 md:col-6">
                                <span :style="{ color: 'red' }">*</span>
                                <label for="name">Nome Fantasia</label>
                                <InputText
                                    id="name"
                                    v-model.trim="form.name"
                                    required="true"
                                    autocomplete="off"
                                    :class="{ 'p-invalid': submitted && !form.name }"
                                />
                                <small class="p-error" v-if="submitted && !form.name">Nome fantasia é obrigatório.</small>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="field col-6">
                                <label for="state_registration">Inscrição Estadual</label>
                                <InputText id="state_registration" v-model.trim="form.stateRegistration" autocomplete="off" />
                            </div>
                            <div class="field col-6">
                                <label for="municipal_registration">Inscrição Municipal</label>
                                <InputText id="municipal_registration" v-model.trim="form.municipalRegistration" autocomplete="off" />
                            </div>
                        </div>
                        <div class="grid">
                            <div class="field col-12 md:col-6">
                                <span :style="{ color: 'red' }">*</span>
                                <label for="tipo_inscricao">Tipo de Local</label>
                                <Dropdown
                                    showClear
                                    id="tipo_inscricao"
                                    v-model="form.tipoLocal"
                                    :options="tipoLocais"
                                    optionValue="value"
                                    optionLabel="label"
                                    placeholder="Selecione..."
                                    :class="{ 'p-invalid': submitted && !form.tipoLocal }"
                                />
                                <small class="p-error" v-if="submitted && !form.tipoLocal">Tipo de local é obrigatório.</small>
                            </div>
                            <div class="field col-12 md:col-6">
                                <label for="cnae">CNAE</label>
                                <DropdownCnae id="cnae" v-model="form.cnae" />
                            </div>
                        </div>
                        <div class="grid">
                            <div class="field col-12 md:col-2 lg:col-2">
                                <span :style="{ color: 'red' }">*</span>
                                <label for="risk_level">Grau de Risco</label>
                                <InputText
                                    id="risk_level"
                                    v-model.trim="form.riskLevel"
                                    autocomplete="off"
                                    :class="{ 'p-invalid': submitted && !form.riskLevel }"
                                />
                            </div>
                            <div class="field col-12 md:col-6 lg:col-4" v-if="form.riskLevel > 0 && form.riskLevel < 5">
                                <label for="validade_exame">Validade do último exame clinico para demissional</label>
                                <InputText id="validade_exame" type="text" :disabled="true" :value="getMensagemConfomeGrauDeRisco(form.riskLevel)" />
                            </div>
                            <div class="field col-6 md:col-4 lg:col-3">
                                <label for="DataAssinatura">Data de assinatura do contrato</label>
                                <Calendar id="DataAssinatura" v-model="form.dataAssinaturaContrato" selectionMode="single" :showIcon="true" />
                            </div>
                            <div class="field col-6 md:col-fixed lg:col-3">
                                <label for="produtosServicos">Produtos e Serviços</label>
                                <div class="flex flex-row">
                                    <MultiSelectProdutosServicos class="w-full" id="produtosServicos" v-model="produtosServicosSelecionados" />
                                    <Button class="p-button-text px-2 w-min" @click="validarProdutosEServicosHerdados()" label="Herdar" />
                                </div>
                            </div>
                        </div>
                        <h5 class="mt-3 mb-4">Endereço</h5>
                        <div>
                            <div class="grid">
                                <div class="field col-12 md:col-3">
                                    <span :style="{ color: 'red' }">*</span>
                                    <label for="zip_code">CEP</label>
                                    <InputMask
                                        id="zip_code"
                                        mask="99999-999"
                                        v-model.trim="form.zipCode"
                                        autocomplete="off"
                                        :class="{ 'p-invalid': submitted && !form.zipCode }"
                                    />
                                    <small class="p-error" v-if="submitted && !form.zipCode">CEP é obrigatório.</small>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span :style="{ color: 'red' }">*</span>
                                    <label for="street">Logradouro</label>
                                    <InputText
                                        id="street"
                                        v-model.trim="form.street"
                                        autocomplete="off"
                                        :class="{ 'p-invalid': submitted && !form.street }"
                                    />
                                    <small class="p-error" v-if="submitted && !form.street">Logradouro é obrigatório.</small>
                                </div>
                                <div class="field col-12 md:col-3">
                                    <span :style="{ color: 'red' }">*</span>
                                    <label for="number">Número</label>
                                    <InputText
                                        id="number"
                                        v-model.trim="form.number"
                                        autocomplete="off"
                                        :class="{ 'p-invalid': submitted && !form.number }"
                                    />
                                    <small class="p-error" v-if="submitted && !form.number">Número é obrigatório.</small>
                                </div>
                            </div>
                            <div class="grid">
                                <div class="field col-12 md:col-4">
                                    <label for="address_complement">Complemento</label>
                                    <InputText id="address_complement" v-model.trim="form.addressComplement" autocomplete="off" />
                                </div>
                                <div class="field col-12 md:col-4">
                                    <span :style="{ color: 'red' }">*</span>
                                    <label for="neighborhood">Bairro</label>
                                    <InputText
                                        id="neighborhood"
                                        v-model.trim="form.neighborhood"
                                        autocomplete="off"
                                        :class="{ 'p-invalid': submitted && !form.neighborhood }"
                                    />
                                    <small class="p-error" v-if="submitted && !form.neighborhood">Bairro é obrigatório.</small>
                                </div>
                                <div class="field col-12 md:col-4">
                                    <span :style="{ color: 'red' }">*</span>
                                    <label for="city">Cidade</label>
                                    <DropDownCidade v-model="form.city" :class="{ 'p-invalid': submitted && !form.city }"></DropDownCidade>
                                    <small class="p-error" v-if="submitted && !form.city">Cidade é obrigatório.</small>
                                </div>
                            </div>
                        </div>
                        <h5 class="mt-3 mb-4">Dados Integração</h5>
                        <div class="grid">
                            <div class="field col-12 md:col-3">
                                <label for="codigoSoc">Código SOC</label>
                                <InputText id="codigoSoc" v-model.trim="form.codigoSoc" autocomplete="off" />
                            </div>
                            <div class="field col-12 md:col-3">
                                <label for="codigoExterno">Código externo</label>
                                <InputText id="codigoExterno" v-model.trim="form.codigoExterno" autocomplete="off" />
                            </div>
                            <div class="field col-12 md:col-3" v-if="form.cliente?.companyNameGupy">
                                <label for="codigoGupy">Código Gupy</label>
                                <InputText id="codigoGupy" v-model.trim="form.codigoGupy" autocomplete="off" />
                            </div>
                        </div>
                        <div class="field field-checkbox">
                            <InputSwitch id="ativo" v-model="form.ativo" />
                            <label for="ativo" class="m-2">Ativo</label>
                        </div>
                    </div>
                </template>
            </AppFormCadastro>
        </template>
    </AppPanel>
</template>

<script>
import BaseService from '../../services/BaseService';
import { getCurrentCompany } from '@/services/store';
import TipoInscricao from '../../enums/TipoInscricao';
import TipoLocal from '../../enums/TipoLocal';
import DropDownCidade from '../cidades/components/DropdownCidade.vue';
import DropdownCnae from '../../components/input/DropdownCnae.vue';
import DropdownClienteInscricao from '../clientes/components/DropdownClienteInscricao.vue';
import AppInfoManual from '../../components/AppInfoManual.vue';
import AppConfirmDialog from '../../components/AppConfirmDialog.vue';
import MultiSelectProdutosServicos from '../produtos-servicos/components/MultiSelectProdutosServicos.vue';

export default {
    components: {
        DropDownCidade,
        DropdownCnae,
        DropdownClienteInscricao,
        MultiSelectProdutosServicos,
        AppInfoManual,
        AppConfirmDialog
    },
    data() {
        return {
            loading: false,
            tipoInscricao: [],
            tipoLocais: [],
            form: {
                ativo: true,
                tradeName: '',
                tradeCode: '',
                riskLevel: null
            },
            produtosServicosSelecionados: [],
            dialogHerdarProdutosServicos: false,
            mensagemValidadeExame: null,
            cidades: [],
            perPage: 0,
            submitted: false,
            states: [
                { value: null, label: 'Selecione um estado' },
                { value: 'AC', label: 'Acre' },
                { value: 'AL', label: 'Alagoas' },
                { value: 'AP', label: 'Amapá' },
                { value: 'AM', label: 'Amazonas' },
                { value: 'BA', label: 'Bahia' },
                { value: 'CE', label: 'Ceará' },
                { value: 'DF', label: 'Distrito Federal' },
                { value: 'ES', label: 'Espírito Santo' },
                { value: 'GO', label: 'Goiás' },
                { value: 'MA', label: 'Maranhão' },
                { value: 'MT', label: 'Mato Grosso' },
                { value: 'MS', label: 'Mato Grosso do Sul' },
                { value: 'MG', label: 'Minas Gerais' },
                { value: 'PA', label: 'Pará' },
                { value: 'PB', label: 'Paraíba' },
                { value: 'PR', label: 'Paraná' },
                { value: 'PE', label: 'Pernambuco' },
                { value: 'PI', label: 'Piauí' },
                { value: 'RJ', label: 'Rio de Janeiro' },
                { value: 'RN', label: 'Rio Grande do Norte' },
                { value: 'RS', label: 'Rio Grande do Sul' },
                { value: 'RO', label: 'Rondônia' },
                { value: 'RR', label: 'Roraima' },
                { value: 'SC', label: 'Santa Catarina' },
                { value: 'SP', label: 'São Paulo' },
                { value: 'SE', label: 'Sergipe' },
                { value: 'TO', label: 'Tocantins' }
            ]
        };
    },
    created() {
        this.service = new BaseService('/customer/branches');
    },
    async mounted() {
        this.form.tipoInscricao = 'CNPJ';

        this.tipoInscricao = Object.keys(TipoInscricao).map(function (type) {
            return { label: `${TipoInscricao[type]}`, value: `${type}` };
        });

        this.tipoLocais = Object.keys(TipoLocal).map(function (type) {
            return { label: `${TipoLocal[type]}`, value: +type };
        });
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar unidade' : 'Adicionar unidade';
        },
        getCurrentCompany() {
            return getCurrentCompany();
        }
    },
    watch: {
        'form.cnae'(newValue) {
            if (newValue?.grau) {
                this.form.riskLevel = newValue.grau;
            }
        },
        'form.customer'() {
            this.form.customerId = this.form.customer?.id;
        },
        getCurrentCompany(newValue, oldValue) {
            if (newValue !== oldValue) {
                alert('Não é possível trocar a empresa no fluxo de criação/edição de um cadastro!');
                this.$router.push({ path: 'list' });
            }
        }
    },
    methods: {
        async onBeforeSave() {
            if (this.hasError()) {
                return;
            }
            this.form.produtoServicoUnidade = [];
            this.produtosServicosSelecionados.forEach((element) => {
                this.form.produtoServicoUnidade.push({ produtoServicoId: element.id });
            });
            this.submitted = true;
        },
        async onAfterSave(response) {
            if (response.data.statusSincronizacaoSoc == 'ERRO') {
                this.$toast.add({ severity: 'error', summary: 'Erro na integração com SOC. Verifique o log de erros.', life: 3000 });
            }
            this.submitted = false;
        },
        onLoadDataEdit(data) {
            this.form = data;
            this.produtosServicosSelecionados = this.form.produtoServicoUnidade.map((r) => r.produtoServico);
            this.form.dataAssinaturaContrato = this.$filters.jsonToDate(this.form.dataAssinaturaContrato);
        },
        async onValidate(cbSuccess, cbError) {
            if (this.hasError()) {
                return cbError();
            }

            return cbSuccess();
        },
        hasError() {
            const camposNaoPreenchidos = document.getElementsByClassName('p-invalid');
            if (camposNaoPreenchidos.length) {
                this.$toast.add({ severity: 'error', summary: 'Erro ao salvar a Unidade!', detail: 'Há campos não preenchidos', life: 3000 });
                return true;
            }
        },
        validarProdutosEServicosHerdados(sobrescrever) {
            const produtosCliente = this.form.cliente.produtosServicosCliente?.map((r) => r.produtoServico);
            const produtosClienteIds = produtosCliente?.map((produtoCliente) => produtoCliente.id);
            const mesmaQuatidade = this.produtosServicosSelecionados?.length === produtosCliente?.length;
            const produtosSaoIguais = this.produtosServicosSelecionados.every((produto) => {
                return produtosClienteIds.includes(produto.id);
            });

            if (!produtosCliente?.length) return;

            if (produtosCliente?.length && (!this.produtosServicosSelecionados?.length || sobrescrever)) {
                this.produtosServicosSelecionados = produtosCliente;
                this.dialogHerdarProdutosServicos = false;
            } else if (!mesmaQuatidade || !produtosSaoIguais) {
                this.dialogHerdarProdutosServicos = true;
            }
        },
        confirmarCopia(event) {
            this.$confirm.require({
                target: event.currentTarget,
                message: 'Os dados já inseridos serão sobrescritos. Tem certeza de que deseja continuar?',
                icon: 'pi pi-exclamation-triangle',
                acceptIcon: 'pi pi-check',
                rejectIcon: 'pi pi-times',
                acceptLabel: 'Sim',
                rejectLabel: 'Não',
                accept: async () => {
                    try {
                        this.loading = true;
                        const customerService = new BaseService('/customers');
                        const responseCustomer = await customerService.findById(this.form.customerId);
                        const responseCnae = await this.getCnaeDoCliente(responseCustomer);
                        this.copiarDadosCadastroCliente(responseCustomer.data, responseCnae);
                        this.loading = false;
                        this.$toast.add({ severity: 'info', summary: 'Informação', detail: 'Dados do cliente copiados com sucesso', life: 3000 });
                    } catch (error) {
                        this.loading = false;
                        this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Não foi possível copiar os dados do cliente', life: 3000 });
                    }
                }
            });
        },
        async getCnaeDoCliente(cliente) {
            const cnaeService = new BaseService('/cnaes');
            if (!cliente?.data?.idCnae) return;
            return await cnaeService.findById(cliente.data.idCnae);
        },
        copiarDadosCadastroCliente(customer, cnae) {
            this.form.tradeName = customer.tradeName;
            this.form.name = customer.name;
            this.form.tipoInscricao = customer.tipoInscricao;
            this.form.tradeCode = customer.inscricao;
            this.form.stateRegistration = customer.inscricaoEstadual;
            this.form.municipalRegistration = customer.inscricaoMunicipal;

            if (!cnae) return;

            this.form.cnae = cnae.data;
        },
        onChangeTipoInscricao() {
            this.form.tradeCode = '';
        },
        getMensagemConfomeGrauDeRisco(grauRisco) {
            switch (grauRisco) {
                case '1':
                case '2':
                    this.mensagemValidadeExame = 'Validade para exames realizados em até 135 dias';
                    break;
                case '3':
                case '4':
                    this.mensagemValidadeExame = 'Validade para exames realizados em até 90 dias';
                    break;
                default:
                    break;
            }

            return this.mensagemValidadeExame;
        }
    }
};
</script>

<style scoped lang="scss">
#customer_id {
    border-radius: 6px;
}
</style>
