<template>
    <AppMultiselect
        v-model="value"
        @change="change"
        dataKey="id"
        :service="$serviceDocumentos"
        optionLabel="nome"
    />
</template>
<script>
import BaseService from '@/services/BaseService'

export default {
    name: 'MultiSelectProdutosServicos',
    props: {
        modelValue: {
            type: Object
        },
        itemSize: {
            type: Number,
            default: 20
        },
        change: {
            type: Function
        },
        idValue: {
            type: Number
        }
    },    
    emits: ['update:modelValue'],
    data() {
        return {
            $serviceFuncionario: null
        }
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    created() {
        this.$serviceDocumentos = new BaseService('/produtos-servicos');
    }
};
</script>
