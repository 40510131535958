const TipoLocal = Object.freeze({
  1: 'Estabelecimento do empregador no Brasil',
  2: 'Estabelecimento do empregador no exterior',
  3: 'Estabelecimento de terceiros onde o empregador presta serviços',
  4: 'Via pública',
  5: 'Área rural',
  6: 'Embarcação',
  9: 'Outros',
});

export default TipoLocal;
